import { isValidEmail, containsOnlyNumbersSignsAndBrackets, containsOnlyNumbersLettersSlashAndMinus, containsOnlyNumbersLettersAndSlash, containsOnlyNumbersSlashAndMinus, containsOnlyTextSlashMinusAndBrackets } from '../utils/validators';


export const MachineOrderFormValidation = (qr, mods_data, customer_data, validateFields, accountNumberError) => {
    const validateFieldsCopy = {...validateFields};
    let isAnyEmptyFields = false;
    if (!qr) {
      validateFieldsCopy.qr = 'Please Select QR';
      isAnyEmptyFields = true;
    } else {
      validateFieldsCopy.qr = '';
    }

    if (!mods_data?.sales_order_number?.trim()) {
      validateFieldsCopy.sales_order_number = 'Please add Sales Order Number';
      isAnyEmptyFields = true;
    } else if (!containsOnlyNumbersLettersAndSlash(mods_data?.sales_order_number)) {
      validateFieldsCopy.sales_order_number = "No special characters or space allowed except '/'";
      isAnyEmptyFields = true;
    } else {
      validateFieldsCopy.sales_order_number = '';
    }

    if (!mods_data?.customer_po?.trim()) {
      validateFieldsCopy.customer_po = 'Please add Customer PO';
      isAnyEmptyFields = true;
    } else if (!containsOnlyNumbersLettersSlashAndMinus(mods_data?.customer_po)) {
      validateFieldsCopy.customer_po = "No special characters or space allowed except '-'";
      isAnyEmptyFields = true;
    } else {
      validateFieldsCopy.customer_po = '';
    }

    // Check customer detail fields
    if (customer_data?.account_number?.trim() && !containsOnlyNumbersSlashAndMinus(customer_data?.account_number)) {
      validateFieldsCopy.account_number = "No special characters or space allowed except '/' , '-' ";
    } else if (accountNumberError) {
      validateFieldsCopy.account_number = 'Cannot Edit or Remove Account Number';
      isAnyEmptyFields = true;
    } else {
      validateFieldsCopy.account_number = '';
    }

    if (!customer_data?.name?.trim()) {
      validateFieldsCopy.name = 'Please add Customer Name';
      isAnyEmptyFields = true;
    } else if (!containsOnlyTextSlashMinusAndBrackets(customer_data?.name)) {
      validateFieldsCopy.name = "No special characters or number allowed except '/', '-', '(', ')'";
      isAnyEmptyFields = true;
    } else {
      validateFieldsCopy.name = '';
    }

    if (!customer_data?.company_name?.trim()) {
      validateFieldsCopy.company_name = 'Please add Company Name';
      isAnyEmptyFields = true;
    } else {
      validateFieldsCopy.company_name = '';
    }

    if (!customer_data?.primary_contact?.trim()) {
      validateFieldsCopy.primary_contact = 'Please add Primary Contact';
      isAnyEmptyFields = true;
    } else if (!containsOnlyNumbersSignsAndBrackets(customer_data?.primary_contact)) {
      validateFieldsCopy.primary_contact = 'Please add Valid Contact Number';
      isAnyEmptyFields = true;
    } else {
      validateFieldsCopy.primary_contact = '';
    }

    if (!customer_data?.accounting_contact?.trim()) {
      validateFieldsCopy.accounting_contact = 'Please add Accounting Contact';
      isAnyEmptyFields = true;
    } else if (!containsOnlyNumbersSignsAndBrackets(customer_data?.accounting_contact)) {
      validateFieldsCopy.accounting_contact = 'Please add Valid Contact Number';
      isAnyEmptyFields = true;
    } else {
      validateFieldsCopy.accounting_contact = '';
    }

    if (!customer_data?.primary_email?.trim()) {
      validateFieldsCopy.primary_email = 'Please add Primary Email';
      isAnyEmptyFields = true;
    } else if (!isValidEmail(customer_data?.primary_email)) {
      validateFieldsCopy.primary_email = 'Please add valid Email Address';
      isAnyEmptyFields = true;
    } else {
      validateFieldsCopy.primary_email = '';
    }

    if (!customer_data?.accounting_email?.trim()) {
      validateFieldsCopy.accounting_email = 'Please add Accounting Email';
      isAnyEmptyFields = true;
    } else if (!isValidEmail(customer_data?.accounting_email)) {
      validateFieldsCopy.accounting_email = 'Please add valid Email Address';
      isAnyEmptyFields = true;
    } else {
      validateFieldsCopy.accounting_email = '';
    }

    if (!customer_data?.bill_to?.trim()) {
      validateFieldsCopy.bill_to = 'Please add Bill To Address';
      isAnyEmptyFields = true;
    } else {
      validateFieldsCopy.bill_to = '';
    }

    if (!customer_data?.ship_to?.trim()) {
      validateFieldsCopy.ship_to = 'Please add Ship To Address';
      isAnyEmptyFields = true;
    } else {
      validateFieldsCopy.ship_to = '';
    }

    if (!customer_data?.shipment_date?.trim()) {
      validateFieldsCopy.shipment_date = 'Please add Date Of Shipment';
      isAnyEmptyFields = true;
    } else {
      validateFieldsCopy.shipment_date = '';
    }
    
  // } else if (!isFutureDate(customer_data?.shipment_date)) {
  //   validateFieldsCopy.shipment_date = "Shipment date can't be in past";
  //   isAnyEmptyFields = true;
  // }
    return [isAnyEmptyFields, validateFieldsCopy];
  };