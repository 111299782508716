import { useState, useContext } from 'react'
import AuthContext from '../Context/AuthContext'
import ButtonSolid from '../Components/ButtonSolid'
import { baseURL } from '../config'
import axios from 'axios'
import PopupWithClose from '../Components/PopupWithClose';
import AssetList from '../Components/AssetList';
import LoadingAnimation from '../Components/LoadingAnimation';

function TemplateAssemblyList({
    fetchingTemplateDetails,
    setToggleSearchPopup,
    templateDetails,
    addedItems,
    templateModelDesc,
    selectedAssembly,
    setSelectedAssembly,
    setDisableReset
}) {
    const [filesPopup, setFilesPopup] = useState(false)
    const [fetchingAssets, setFetchingAssets] = useState(true)
    const [assetsData, setAssetsData] = useState([])
    const { token, logout } = useContext(AuthContext); 

    const fetchAssets = async (param) => {
        try {   
          setFetchingAssets(true)
          const response = await axios.get(`${baseURL}/asset/getAssets`, {
            headers: {
                "ngrok-skip-browser-warning": "any",
                "Content-Type": "multipart/form-data",
                "x-access-token": token
            },
            params: {
                asset_ids: param,
            }
          });
          setAssetsData(response.data.data.assetData)
        } catch (error) {
          console.error('Error:', error);
          if (error.response?.status === 401) {
            logout();
          }
        } finally {
          setFetchingAssets(false)
        }
      };

    const showDrawing = (assets) => {
        setFilesPopup(true)
        if (assets.length > 0) {
          fetchAssets(assets)
        } else {
          setAssetsData([])
          setFetchingAssets(false)
        }
      }

    const handleCheckboxClick = (item) => {
        setSelectedAssembly(prev => prev.includes(item) ? prev.filter(i => i !== item) : [...prev, item]);
        setDisableReset(false)
    };
  return (
    <div className='bg-white shadow-md rounded-lg mb-5 w-full overflow-hidden'>
        <div className='bg-white pb-4'>
            <div className='flex flex-wrap items-start md:items-center justify-between py-4 px-6'>
                <p className='text-2xl font-semibold mr-2 mb-2 md:mb-0 pr-2'>Assemblies</p>
                <div className='flex'>
                <ButtonSolid 
                    buttonText={"Add Assembly"}
                    onClick={() => setToggleSearchPopup(true)}
                    disabled={fetchingTemplateDetails}
                    className='lg:hidden'
                />
                </div>
            </div>
            {fetchingTemplateDetails ?
            <LoadingAnimation /> :
            <div className='overflow-x-auto'>
                {Object.keys(templateDetails).map((key, index) => (
                (templateDetails[key].length > 0) &&
                <div key={key} className={`border border-gray-300 mb-6 mx-6 rounded-lg overflow-hidden min-w-fit  transition-all ease-in-out duration-300 `} >
                    <div className='py-4 px-4 text-xs md:text-base font-semibold bg-slate-50 capitalize text-gray-700 cursor-default'>{key}</div>
                    <table key={`${key}-${index}`} id={key} className='w-full min-w-[525px] text-sm text-left rtl:text-right cursor-default'>
                        <thead>
                            <tr className='text-xs md:text-base text-gray-400 uppercase border-b-2 border-dotted h-10'>
                                <th className="px-4">{(index === 0) ? 'MODEL#' : 'ASSEMBLY#'}</th>
                                <th className="px-4">FILE</th>
                                <th className="px-4">ASSEMBLY DESCRIPTION</th>
                            </tr>
                        </thead>
                        {templateDetails[key]?.map((item, itemIndex) => (
                        <tbody key={itemIndex}>
                            <tr className={`hover:bg-slate-200 border ${addedItems.includes(item._id) ? 'gradient_background_animated' : 'bg-transparent'} text-sm md:text-base text-gray-600`}>
                                <td onClick={() => handleCheckboxClick(item._id)} className="pl-4 py-6 min-w-[250px]">
                                <div>
                                    {(item?.model_number === templateModelDesc["name"]) ? '' : <input type="checkbox" readOnly={true} checked={selectedAssembly.includes(item._id)} className='pointer-events-none h-6 w-6 text-white indeterminate:bg-gray-300 rounded-md accent-gray-500 align-middle mb-2'/>}
                                    <p className='inline-block ml-2'>{item?.model_number} {item?.assembly_number}</p>
                                </div>
                                </td>
                                <td className="px-4 py-6 md:min-w-[100px]">
                                    <span onClick={() => {showDrawing(item?.assets)}} className='cursor-pointer border-b border-gray-600 hover:border-orange-600'>Drawing</span>
                                </td>
                                <td className="px-4 py-6 w-full">{item?.assembly_description}{item?.model_description}</td>
                            </tr>
                        </tbody>))}
                    </table>
                </div>))}
            </div>} 
            {filesPopup && 
            (<PopupWithClose closePopup={setFilesPopup}>
                <AssetList data={assetsData} fetchingAssets={fetchingAssets}/>
            </PopupWithClose>)
            }
        </div>
    </div>
  )
}

export default TemplateAssemblyList