import { useContext, useState } from 'react'
import AssetList from '../Components/AssetList'
import { baseURL } from '../config'
import axios from 'axios'
import AuthContext from '../Context/AuthContext'
import LoadingAnimation from '../Components/LoadingAnimation'
import PopupWithClose from '../Components/PopupWithClose'
import { SvgArrowMobile } from "./Icons";


function AssembliesTable({ fetchingTemplateDetails, templateDetails }) {
  return (
    <div>
      <div className='w-full'>
        <div className='bg-white shadow-md rounded-lg mb-5 w-full overflow-hidden my-3 md:my-5'>
          <div className='overflow-x-scroll bg-white pb-4'>
            <div className='flex flex-wrap items-start md:items-center justify-between py-4 px-6'>
              <p className='text-2xl font-semibold mr-2 mb-2 md:mb-0 pr-2'>Assemblies</p>
            </div>
            {fetchingTemplateDetails ? (
              <LoadingAnimation />
            ) : (
              Object.keys(templateDetails).map((key, first) => (
                templateDetails[key].length > 0 && (
                  <div key={`${key}-${first}`} className='mb-3 md:mb-8 mx-3 md:mx-6 overflow-hidden min-w-fit'>
                    <div className='py-4 px-4 bg-slate-100 text-xl font-semibold capitalize text-gray-700 cursor-default border border-slate-400 border-b-0 rounded-t-lg'>
                      {key}
                    </div>
                    <div className='border-x border-slate-400'>
                      <div className='text-xs flex items-center md:text-base text-gray-400 uppercase bg-slate-50 border-t-2 border-dashed border-slate-300 h-10 font-medium'>
                        <div className="px-2 md:px-4 min-w-[200px]">{first === 0 ? 'MODEL#' : 'ASSEMBLY#'}</div>
                        <div className='w-full flex max-w-[1200px] ml-auto'>
                          <div className="px-2 md:px-4 w-[10%]">FILE</div>
                          <div className="px-2 md:px-4 w-[90%] ml-6">ASSEMBLY DESCRIPTION</div>
                        </div>
                      </div>
                    </div>
                    {templateDetails[key].map((item, index) => (
                      <AssembliesTreeView key={`main_${key}_${index}`} item={item} />
                    ))}
                  </div>
                )
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}


const AssembliesTreeNode = ({ item, _key, embedLevel }) => {
  const [filesPopup, setFilesPopup] = useState(false)
  const [assetsData, setAssetsData] = useState([])
  const { token, logout } = useContext(AuthContext);
  const [fetchingAssets, setFetchingAssets] = useState(true)
  const embSpacing = 200-(33 * (embedLevel-1))

  const fetchAssets = async (param) => {
    try {
      setFetchingAssets(true)
      const response = await axios.get(`${baseURL}/asset/getAssets`, {
          headers: {
              "ngrok-skip-browser-warning": "any",
              "Content-Type": "multipart/form-data",
              "x-access-token": token
          },
          params: {
              asset_ids: param,
          }
      });
      setAssetsData(response.data.data.assetData)
    } catch (error) {
      console.error('Error:', error);
      if (error.response?.status === 401) {
          logout();
      }
    } finally {
      setFetchingAssets(false)
    }
  };

  const showDrawing = (assets) => {
    setFilesPopup(true)
    if (assets.length > 0) {
      fetchAssets(assets)
    } else {
      setAssetsData([])
      setFetchingAssets(false)
    }
  }

  return (
    <>
      <div key={_key} className={`w-full border -mt-[1px] border-slate-400 bg-white hover:bg-gray-100 relative z-10`}>
        <div className='flex md:text-base font-medium text-gray-500'>
          <div className='px-2 md:px-4 flex items-center' style={{minWidth: `${embSpacing}px`}}>
            <p className='w-full max-w-44'>{item?.model_number} {item?.assembly_number}</p>
          </div>
          <div className='w-full max-w-[1200px] ml-auto flex'>
            <div className="px-2 md:px-4 w-[10%] py-5">
              <span onClick={() => {showDrawing(item?.assets)}} className='border-b border-slate-400 cursor-pointer hover:border-orange-600'>Drawing</span>
            </div>
            <div className="px-2 md:px-4 py-5 w-[90%] ml-6">{item?.assembly_description}{item?.model_description}</div>
          </div>
        </div>
      </div>
      {filesPopup && 
        (<PopupWithClose closePopup={setFilesPopup}>
          <AssetList data={assetsData} fetchingAssets={fetchingAssets}/>
        </PopupWithClose>)
      }
      {item.embedded_assemblies.length > 0 &&
      <div className="ml-4">
        <div className="border-l-2 border-[#515BA5]">
            {item.embedded_assemblies.map((item, index) => (
              <div key={index} className='flex last:pl-[4px] last:-ml-[4px] bg-white'>
                <span className='-ml-[2px]'><SvgArrowMobile /></span>            
                <div className="flex flex-col w-full min-w-[880px]">
                    <AssembliesTreeView item={item} _key={`em_${_key}_${index}`} embedLevel={embedLevel + 1} />
                </div>
              </div>
            ))}
        </div>
      </div>}
    </>
  )
}

const AssembliesTreeView = ({ item, _key, embedLevel=1 }) => {

  return (
    <AssembliesTreeNode item={item} _key={_key} embedLevel={embedLevel}/>
  );
};

export default AssembliesTable