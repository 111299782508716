import { useContext, useEffect, useRef, useState } from 'react';
import AuthContext from '../Context/AuthContext';
import Popup from '../Components/Popup';
import FileUploadDragDrop from '../Components/FileUploadDragDrop';
import ButtonSolid from '../Components/ButtonSolid';
import ButtonOutline from '../Components/ButtonOutline';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseURL } from '../config';
import toast from 'react-hot-toast';
import OverlayLoading from '../Components/OverlayLoading';
import LoadingAnimation from '../Components/LoadingAnimation';
import PaginationComponent from '../Components/PaginationComponent';
import MultiSelectDropdown from '../Components/MultiSelectDropdown';
import Searchbar from '../Components/Searchbar';
import FileSyncContext from '../Context/FileSyncContext';
import ProductLibraryContext from '../Context/ProductLibraryContext';

function ProductLibraryListView() {
  const navigate = useNavigate()
  const [bulkUploadOverlay, setBulkUploadOverlay] = useState(false)
  const [fileUploadError, setFileUploadError] = useState("")
  const [bulkUploadPopup, setBulkUploadPopup] = useState(false)
  const [disablebulkUpload, setDisableBulkUploadPopup] = useState(false)
  const [bulkUploadFile, setBulkUploadFile] = useState();
  const [assemblyData, setAssemblyData] = useState([]);
  const [fetchingAssemblyData, setFetchingAssemblyData] = useState(true);
  const [fetchingAssemblyType, setFetchingAssemblyType] = useState(true);
  const [newBulkUpload, setNewBulkUpload]= useState(false);
  const { token, logout } = useContext(AuthContext);
  const { checkSyncControlPackageStatus, checkLocalStorage, checkSyncAssetsStatus } = useContext(FileSyncContext);
  const { params, setParams, searchInput, setSearchInput } = useContext(ProductLibraryContext);
  const [totalPages, setTotalPages] = useState(1);
  const [totalAssemblies, setTotalAssemblies] = useState();
  const [assemblyType, setAssemblyType] = useState();

  const [initiatingSyncCP, setInitiatingSyncCP] = useState(false)
  const [syncCPLoading, setSyncCPLoading] = useState(false);

  const [initiatingSyncAssets, setInitiatingSyncAssets] = useState(false);
  const [syncAssetsLoading, setSyncAssetsLoading] = useState(false);

  const [disableSyncAssets, setDisableSyncAssets] = useState(false);
  
  const handleSetAssemblyFilter = (value) => {
    setParams(prevParams => ({
      ...prevParams,
      filter: value,
      page: 1
    }));
  }

  const useDebounce = (func, delay) => {
    const debounceRef = useRef(null);
    
    const debouncedFunc = (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = window.setTimeout(() => {
        func(...args);
      }, delay);
    };
  
    debouncedFunc.cancel = () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  
    return debouncedFunc;
  };
  
  const handleSetSearchFilter = useDebounce((value) => {
    setParams(prevParams => ({
      ...prevParams,
      search: value,
      page: 1
    }))
  }, 800);

  const handleSearchFilter = (input) => {
    setSearchInput(input)
    handleSetSearchFilter(input)
  }

  const handleCurrentPage = (value) => {
    setParams(prevParams => ({
      ...prevParams,
      page: value
    }));
  }

  const handleLimitValue = (value) => {
    setParams(prevParams => ({
      ...prevParams,
      limit: value
    }));
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetchingAssemblyData(true)
        const response = await axios.get(`${baseURL}/assembly/getAllAssemblies`, 
        {headers: {
          "ngrok-skip-browser-warning":"any",
          "x-access-token": token
        },
        params: params });
        if (response?.status === 204) {
          setAssemblyData('no data');
          setTotalPages(1)
        } else {
          setAssemblyData(response.data.data.assemblyData)
          setTotalPages(response.data.data.pagination.totalPages)
          setTotalAssemblies(response.data.data.pagination.totalAssemblies)
        }
      } catch (error) {
        if (error.response?.status === 401) {
          logout();
        } else{
            console.error('Error:', error);
        }
      } finally {
        setFetchingAssemblyData(false)
      }
    };

    fetchData();
  }, [newBulkUpload, params, logout, token]);  

  useEffect(() => {
    const fetchAssemblyType = async () => {
      try {
        setFetchingAssemblyType(true)
        const response = await axios.get(`${baseURL}/template/getAssemblyTypes`, 
        {headers: {
          "ngrok-skip-browser-warning":"any",
          "x-access-token": token
        }})
        setAssemblyType(response.data.data)
      } catch (error) {
        if (error.response?.status === 401) {
          logout();
        }else{
            console.error('Error:', error);
        }
      } finally {
        setFetchingAssemblyType(false)
      }
    };

    fetchAssemblyType()
  }, [logout, token]);

  useEffect(() => {
    setDisableSyncAssets(sessionStorage.getItem('disableSyncAssets') === 'true' || false);
    setSyncCPLoading(sessionStorage.getItem('syncCPLoading') === 'true' || false);
    setSyncAssetsLoading(sessionStorage.getItem('syncAssetsLoading') === 'true' || false)
  }, [checkLocalStorage]);

  const assembly_detail = (param) => {
    navigate(`/assembly_detail/${param}`)
  }

  const syncAssets = async() => {
    try {
      setInitiatingSyncAssets(true)
      const response = await axios.post(`${baseURL}/asset/addAssets`,
        {}, // No data to send in the request body
        {
          headers: {
            "x-access-token": token
          }
        }
      );
      if (response.status === 201) {
        toast.success("Successfully Initiated Sync Assets");
        sessionStorage.setItem('syncAssetsLoading', 'true');
        setSyncAssetsLoading(true)
        checkSyncAssetsStatus()
      } else if (response.status === 204){
        toast.error("The process is already running");
        sessionStorage.setItem('syncAssetsLoading', 'true');
        setSyncAssetsLoading(true)
      }
    } catch (error) {
      toast.error('Error Syncing Aessets:', error);
    } finally {
      setInitiatingSyncAssets(false)
    }
  }

  const bulkUploadFile_DragDropUpload = (event) => {
    event.preventDefault();
    setFileUploadError('')
    const files = event.dataTransfer.files;
    if (files.length > 1) {
      setFileUploadError('Please upload only one file at a time.');
      return;
    }
    const file = files[0];
    if (file.type !== 'text/csv' && !file.name.toLowerCase().endsWith('.csv')) {
      setFileUploadError('Please upload a CSV file only.');
      return;
    }
    
    const droppedFile = files[0];
    setBulkUploadFile(droppedFile); 
  };

  const syncControlPackages = async () => {
    try {
      setInitiatingSyncCP(true)
      const response = await axios.post(
        `${baseURL}/asset/addCPAssets`,
        {},
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      if (response.status === 201) {
        toast.success("Successfully Initiated Sync Control Package");
        sessionStorage.setItem('syncCPLoading', 'true');
        setSyncCPLoading(true)
        checkSyncControlPackageStatus()
      } else if (response.status === 204){
        toast.error("The process is already running");
        sessionStorage.setItem('syncCPLoading', 'true');
        setSyncCPLoading(true)
      } 
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong");
      sessionStorage.setItem('syncCPLoading', 'false');
      setSyncCPLoading(false)
    } finally {
      setInitiatingSyncCP(false)
    }
  };

  const bulkUploadFile_ManualUpload = (e) => {
    const files = e.target.files;
    if (!files || files.length !== 1) {
      setFileUploadError('Please upload only one file at a time.');
      return;
    }
    const file = files[0];
    if (file.type !== 'text/csv' && !file.name.toLowerCase().endsWith('.csv')) {
      setFileUploadError('Please upload a CSV file only.');
      return;
    }
    setBulkUploadFile(file);
  };

  const handleBulkUploadSubmit = async e => {
    e.preventDefault();
    if (bulkUploadFile) {
      const formData = new FormData();
      formData.append('csvFile', bulkUploadFile);
    
      try {
        setBulkUploadOverlay(true)
        setDisableBulkUploadPopup(true)
        const response = await axios.post(`${baseURL}/assembly/bulkUpload`, formData,  {headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": token
        }}
      );
        if (response.status === 200) {
          toast.success('File Uploaded Successfully');
          setNewBulkUpload(!newBulkUpload)
        } else {
          toast.error('Failed To Upload File');
        }
      } catch (error) {
        if (error.response.status === 400){
          toast.error('Please Upload Valid CSV File');
        } else {
          toast.error('Error Uploading File: ' + error.message);
        }
      } finally {
        setBulkUploadFile()
        setBulkUploadPopup(false)
        setBulkUploadOverlay(false)
        setDisableBulkUploadPopup(false)
      }
    } else {
      setFileUploadError('Please add a CSV file.');
    }
  };

  
  if (!fetchingAssemblyData && assemblyData.length === 0) {
    return (
      <div className='py-3 md:py-6'>
        <div className="overflow-x-auto p-3 bg-white shadow-md rounded-lg py-10">
          <h3 className='text-lg font-medium mb-5 text-center'>Create Assembly List</h3>
          <p className='text-sm mb-2 text-center'>{bulkUploadFile ? 'Uploaded File' : 'Upload File'}</p>
          <form onSubmit={handleBulkUploadSubmit}>
            <FileUploadDragDrop className='mx-auto' handleDrop={bulkUploadFile_DragDropUpload} handleUpload={bulkUploadFile_ManualUpload} file={bulkUploadFile} errorMessage={fileUploadError}/>
            <div className='flex gap-4 justify-center items-center my-5'>
              <ButtonSolid buttonText="Bulk Upload" type='submit' disabled={fetchingAssemblyData || disablebulkUpload}/>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <>
    {bulkUploadOverlay && (
      <OverlayLoading loadingText='Uploading Data Please Wait...'/>
    )}
    {initiatingSyncAssets && (
      <OverlayLoading loadingText='Initiating Sync Assets...'/>
    )}
    {initiatingSyncCP && (
      <OverlayLoading loadingText='Initiating Sync Control Packages...'/>
    )}
    <div className='pb-6'>
      <div className='flex flex-wrap md:flex-nowrap justify-between items-center gap-4 py-5'>
        <p className='text-lg font-medium md:text-xl md:font-semibold pr-3'>Product Library (Assembly List)</p>
        <div className='flex gap-4 flex-wrap'>
          <ButtonSolid 
            disabled={fetchingAssemblyData || disableSyncAssets}
            isLoading={syncAssetsLoading}
            buttonText="Sync Assets"
            loadingText={'Syncing Assets'}
            onClick={syncAssets}
          />
          <div className='flex flex-col'>
            <ButtonSolid 
              disabled={fetchingAssemblyData || !disableSyncAssets}
              buttonText="Sync Control Packages"
              isLoading={syncCPLoading}
              loadingText={'Syncing Control Packages'}
              onClick={syncControlPackages}
              errorMessage={'(This process would take a while...)'}
            />
            {syncCPLoading && <p className='text-sm font-extralight italic'>This process would take a while...</p>}
          </div>
        </div>
      </div>
      <div className="p-3 bg-white shadow-md rounded-lg min-h-80">
        <div className='flex justify-between flex-wrap px-1 md:pt-3 md:pb-6 items-center gap-3'>
          <div className='pr-2 whitespace-nowrap text-xl font-medium'>Product Information</div>
          <div className="flex flex-wrap gap-4 pb-4 md:pb-0 w-full md:w-auto">
            <Searchbar 
              value={searchInput}
              onChange={(e) => {handleSearchFilter(e.target.value)}}
              className={fetchingAssemblyData ? 'bg-gray-100' : ''}
            />
            <MultiSelectDropdown 
              options={assemblyType}
              disabled={fetchingAssemblyData || fetchingAssemblyType}
              setValue={handleSetAssemblyFilter}
              placeholder={"Add Assembly Type Filter"}
            />
            <ButtonSolid
              disabled={fetchingAssemblyData || syncAssetsLoading || syncCPLoading}
              buttonText='Bulk Upload'
              onClick={() => {setBulkUploadPopup(true)}}
            />
          </div>
        </div>
        {bulkUploadPopup && (
          <Popup>
            <h3 className='text-lg font-medium mb-5'>Create Assembly List</h3>
            <p className='text-sm mb-2'>{bulkUploadFile ? 'Uploaded File' : 'Upload File'}</p>
            <form onSubmit={handleBulkUploadSubmit}>
              <FileUploadDragDrop 
                handleDrop={bulkUploadFile_DragDropUpload} 
                handleUpload={bulkUploadFile_ManualUpload} 
                file={bulkUploadFile} 
                errorMessage={fileUploadError}
              />
              <div className='flex gap-4 justify-center items-center my-5'>
                <ButtonOutline
                  buttonText="Cancel"
                  onClick={() => {setBulkUploadFile(); setBulkUploadPopup(false)}}
                />
                <ButtonSolid
                  buttonText="Bulk Upload"
                  type='submit'
                />
              </div>
            </form>
          </Popup>
        )}
        {fetchingAssemblyData ? 
          (<LoadingAnimation />):
          <div className='overflow-x-auto'>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 border border-gray-300">
              <thead className="text-gray-500 uppercase text-md bg-slate-50 border-b border-gray-300">
              <tr>
                <th className="px-4 py-3 min-w-[110px] border-r border-gray-300">Model #</th>
                <th className="px-4 py-3 border-r border-gray-300">Model Description</th>
                <th className="px-4 py-3 min-w-[125px] border-r border-gray-300">Assembly #</th>
                <th className="px-4 py-3 min-w-[180px] border-r border-gray-300">Assembly Type</th>
                <th className="px-4 py-3">Assembly Description</th>
                {/* <th className="px-4 py-3 text-center">Action</th> */}
              </tr>
              </thead>
              {(assemblyData === 'no data') ? 
              <tbody>
                <tr><td colSpan={3} className='p-4'>{`No Data found for '${params.search}'`}</td></tr>
              </tbody> :
              <tbody>
                {assemblyData.map((data, index)=>(
                <tr
                key={index}
                className="bg-white border-b hover:bg-slate-200 text-sm md:text-base cursor-pointer text-gray-900"
                onClick={() => assembly_detail(data?._id)}
                >
                  <td className="px-4 py-2 ml:py-6 font-medium whitespace-nowrap border-r border-gray-300">{data?.model_number}</td>
                  <td className="px-4 py-2 ml:py-6 min-w-64 font-medium border-r border-gray-300">{data?.model_description}</td>
                  <td className="px-4 py-2 ml:py-6 border-r border-gray-300">{data?.assembly_number}</td>
                  <td className="px-4 py-2 ml:py-6 border-r border-gray-300">{data?.assembly_type}</td>
                  <td className="px-4 py-2 ml:py-6 min-w-64 border-r">{data?.assembly_description}</td>
                </tr>
                ))}
              </tbody>}
            </table>
            <PaginationComponent
              totalData={totalAssemblies}
              totalPages={totalPages} 
              currentPage={params.page}
              limitValue={params.limit}
              setLimitValue={handleLimitValue}
              setCurrentPage={handleCurrentPage} 
              siblings={2} 
            />
          </div>
        }
      </div>
    </div>
    </>
  )
}

export default ProductLibraryListView