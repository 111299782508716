import { useState } from 'react'
import { useNavigate } from 'react-router'
import ButtonSolid from './ButtonSolid'
import ButtonOutline from './ButtonOutline'
import { DeleteIcon, EditIcon } from './Icons'
import { useContext } from 'react'
import AuthContext from '../Context/AuthContext';
import { baseURL } from '../config';
import toast from 'react-hot-toast';
import axios from 'axios'
import OverlayLoading from './OverlayLoading'
import DeletePopUp from './DeletePopUp'


function MODsTemplateCard({data, refresh, setRefresh, MODPage=false}) {
    const navigate = useNavigate()
    const [overlay, setOverlay] = useState(false)
    const [openDeletePopup, setOpenDeletePopup] = useState(false)
    const [deleteData, setDeleteData] = useState()
    const{token} = useContext(AuthContext)
    const view_template = (data) => {
        MODPage?
            navigate(`/mods_template/mods_template_detail/${data._id}`) :
            navigate(`/machine_orders/mods_template_detail/${data._id}`)
    }
    const edit_template = (data) => {
        navigate(`/mods_template/edit_mods_template_detail/${data._id}`)
    }
    const view_create_mods_data_sheet = (data) => {
        navigate(`/machine_orders/create_mods_data_sheet/${data._id}`)
    }
    const delete_template = async(id) => {
        try {
            setOverlay(true)
            const response = await axios.delete(`${baseURL}/template/deleteTemplate/${id}`, 
            {headers: {
              "ngrok-skip-browser-warning":"any",
              "x-access-token": token
            },
            params: {
              template_id: id
            }});
            if (response.status === 200){
              toast.success('Template Deleted Successfully')
              setRefresh(!refresh)
            }
        } catch (error) {
            toast.error('Something Went Wrong, Template Not Deleted')
        } finally {
            setOverlay(false)
            setDeleteData()
        }
    }

    return (
        <>
            {overlay && <OverlayLoading loadingText='Deleting MOD Template'/>}
            <div className='px-2 py-3 mb-4 w-full md:max-w-[50%] ml:min-w-[410px] ml:max-w-[33.33%]'>
                <div className='bg-white p-5 cursor-default rounded-lg shadow-md h-full min-h-[250px] flex flex-col justify-between hover:scale-[1.01] hover:shadow-xl transition-all ease-in-out'>
                    <div>
                        <div className='flex justify-between items-center mb-4'>
                            <h3 className='bg-green-200 border border-green-500 rounded-full font-medium text-sm text-gray-500 py-1 px-4'>Model# {data.name}</h3>
                            {MODPage && 
                            <div className='flex justify-between items-center gap-3'>
                                <div className='cursor-pointer' onClick={() => edit_template(data)}><EditIcon /></div>
                                <div className='cursor-pointer' onClick={() => {setOpenDeletePopup(true); setDeleteData(data)}}><DeleteIcon /></div>
                            </div>}
                        </div>
                        <p className='text-gray-500'>Assembly Description</p>
                        <p className='mb-5 font-medium text-gray-600'>{data.description}</p>
                    </div>
                    <div className='flex gap-4 flex-wrap ml:flex-nowrap'>
                        <ButtonSolid
                            buttonText="Use template"
                            className="w-full"
                            onClick={() => view_create_mods_data_sheet(data)}
                        />
                        <ButtonOutline
                            buttonText="View template"
                            className="w-full"
                            onClick={() => view_template(data)}
                        />
                    </div>
                </div>
            </div>

            {openDeletePopup &&
                <DeletePopUp onDelete={() => delete_template(deleteData._id)} dataName={`MODSs template ${deleteData.name}`} setOpenDeletePopup={setOpenDeletePopup} />
            }
        </>
    )
}

export default MODsTemplateCard