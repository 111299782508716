import { useContext, useState, useEffect } from 'react'
import ButtonSolid from '../Components/ButtonSolid'
import ButtonOutline from '../Components/ButtonOutline'
import { baseURL } from '../config'
import { useNavigate } from "react-router";
import axios from 'axios'
import AuthContext from '../Context/AuthContext'
import toast from 'react-hot-toast'
import DeletePopUp from '../Components/DeletePopUp'
import OverlayLoading from '../Components/OverlayLoading'
import MODsAssemblyList from '../Components/MODsAssemblyList'
import TemplateHeaders from '../Components/TemplateHeaders'
import { DeleteIcon } from '../Components/Icons'
import PopupWithClose from '../Components/PopupWithClose';
import AssetList from '../Components/AssetList';
import LoadingAnimation from '../Components/LoadingAnimation';
import { useParams } from 'react-router';

function ModsTemplateEdit() {
  const [toggleSearchPopup, setToggleSearchPopup] = useState(false)
  const [fetchingTemplateDetails, setFetchingTemplateDetails] = useState(true)
  const [templateDetails, setTemplateDetails] = useState([])
  const [originalTemplateDetails, setOriginalTemplateDetails] = useState([])
  const [disableReset, setDisableReset] = useState(true)
  const { token, logout } = useContext(AuthContext);
  const [templateModelDesc, setTemplateModelDesc] = useState();
  const [addedItems, setAddedItems] = useState([])
  const [deletedItems, setDeletedItems] = useState([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false)
  const [overlay, setOverlay] = useState(false)
  const [fetchingAssets, setFetchingAssets] = useState(true) 
  const [filesPopup, setFilesPopup] = useState(false)
  const [assetsData, setAssetsData] = useState([])
  const { assembly_id } = useParams();
  const navigate = useNavigate()

  const resetChanges = () => {
    setTemplateDetails(originalTemplateDetails)
    setAddedItems([])
    setDeletedItems([])
    setDisableReset(true)
  }

  const fetchAssets = async (param) => {
      try {   
        setFetchingAssets(true)
        const response = await axios.get(`${baseURL}/asset/getAssets`, {
          headers: {
              "ngrok-skip-browser-warning": "any",
              "Content-Type": "multipart/form-data",
              "x-access-token": token
          },
          params: {
              asset_ids: param,
          }
        });
        setAssetsData(response.data.data.assetData)
      } catch (error) {
        console.error('Error:', error);
        if (error.response?.status === 401) {
            logout();
        }
      } finally {
        setFetchingAssets(false)
      }
    };

  useEffect(() => {
    const fetchData = async (param) => {
      try {
        setFetchingTemplateDetails(true);
        const response = await axios.get(`${baseURL}/template/getTemplateById/${param}`, {
          headers: {
            "ngrok-skip-browser-warning": "any",
            "Content-Type": "multipart/form-data",
            "x-access-token": token
          },
          params: {
            assembly_id: assembly_id
          }
        });
        setTemplateModelDesc(response.data.data.templateDesc);
        setOriginalTemplateDetails(response.data.data.templateData)

        setTemplateDetails(response.data.data.templateData);
      } catch (error) {
          console.error('Error:', error);
      } finally {
        setFetchingTemplateDetails(false);
      }
    };
    
    fetchData(assembly_id);

  }, [assembly_id, token]);

  const showDrawing = (assets) => {
      setFilesPopup(true)
      if (assets.length > 0) {
        fetchAssets(assets)
      } else {
        setAssetsData([])
        setFetchingAssets(false)
      }
  }

  const addAssemblyData = (dataSet) => {
    const assemblyType = dataSet.assembly_type;
    const assemblyToAdd = originalTemplateDetails[assemblyType]?.findIndex(item => item._id === dataSet._id);
    const indexToAdd = templateDetails[assemblyType]?.findIndex(item => item._id === dataSet._id);
    if (((assemblyToAdd === -1) || (originalTemplateDetails[assemblyType] === undefined)) & ((indexToAdd === -1) || (templateDetails[assemblyType] === undefined))) {
      setAddedItems(prevAddedItems => [...prevAddedItems, dataSet._id]);
    }
    
    const undoDelete = deletedItems.findIndex(item => item === dataSet._id);
    if (undoDelete !== -1) {
      // Remove the item from the list
      setDeletedItems ([
          ...deletedItems.slice(0, undoDelete), // Items before the one to remove
          ...deletedItems.slice(undoDelete + 1)  // Items after the one to remove
      ]);
    }

    if ((indexToAdd === -1) || (templateDetails[assemblyType] === undefined)) {
      setTemplateDetails(prevState => ({
        ...prevState, // Clone the existing state
        [assemblyType]: [ // Add new data under the respective assembly type
            ...(prevState[assemblyType] || []), // Clone existing data for the assembly type, if any
            dataSet // Add the new data from the dataSet
        ]
      }));
      setDisableReset(false);
      const element = document.getElementById(assemblyType);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    else {
      toast.error("Assembly Already Exist")
    }
  };

  const deleteAssemblyData = (key, itemId) => {
    if (originalTemplateDetails[key]){
      const indexToDelete = originalTemplateDetails[key].findIndex(item => item._id === itemId);
      if (indexToDelete !== -1) {
        setDeletedItems(prevDeletedItems => [...prevDeletedItems, itemId]);
        setDisableReset(false);
      }
    }

    const undoAdd = addedItems.findIndex(item => item === itemId);
    if (undoAdd !== -1) {
      // Remove the item from the list
      setAddedItems ([
          ...addedItems.slice(0, undoAdd), // Items before the one to remove
          ...addedItems.slice(undoAdd + 1)  // Items after the one to remove
      ]);
    } 

    if (templateDetails[key]) {
      // Find index of object with specified ID
      const indexToDelete = templateDetails[key].findIndex(item => item._id === itemId);
      if (indexToDelete !== -1) {
          // Remove object from array and create new array without the deleted item
          const newTemplateDetails = [...templateDetails[key]];
          newTemplateDetails.splice(indexToDelete, 1);

          // Update state with the new array
          setTemplateDetails({
              ...templateDetails,
              [key]: newTemplateDetails
          });
      } 
    } else {
      toast.error("Something Went Wrong Unable To Delete")
    }
  };

  const saveTemplateChanges = async(id) => {
    if (addedItems.length > 0 || deletedItems.length > 0) {
      try {
        setOverlay(true)
        const response = await axios.put(`${baseURL}/template/modifyTemplate/${id}`, 
        {add_assembly: addedItems,
         delete_assembly: deletedItems
        },
        {headers: {
          "ngrok-skip-browser-warning":"any",
          "x-access-token": token
        }});
        if (response.status === 200){
          toast.success('Template changes saved')
          setDisableReset(true)
          navigate(`/mods_template/mods_template_detail/${id}`)
        }
      } catch (error) {
        toast.error('Something Went Wrong, Template Not Updated')
      } finally {
        setOverlay(false)
      }
    }
    else{
      toast.error('No Changes Made')
    }
  }

  const deleteTemplate = async(id) => {
    try {
      setOverlay(true)
      const response = await axios.delete(`${baseURL}/template/deleteTemplate/${id}`, 
      {headers: {
        "ngrok-skip-browser-warning":"any",
        "x-access-token": token
      },
      params: {
        template_id: id
      }});
      if (response.status === 200){
        toast.success('Template Deleted Successfully')
        navigate('/mods_template')
      }
    } catch (error) {
      toast.error('Something Went Wrong Template Not Deleted')
    } finally {
      setOverlay(false)
    }
  }

  return (
    <>
    {overlay && <OverlayLoading />}
      <div className='flex w-full lg:h-[calc(100vh-80px)]'>
        <div className='w-full lg:pr-3 overflow-y-auto'>
          <div className='flex flex-wrap items-start md:items-center justify-between py-3 md:py-5 gap-2'>
            <TemplateHeaders templateModelDesc={templateModelDesc} loading={fetchingTemplateDetails} modsTemplate={true} />
            <div className='mr-0 ml-auto'> 
              <ButtonOutline 
                buttonText={"Reset Changes"}
                disabled={disableReset}
                className="mr-4 mb-2"
                onClick={() => resetChanges()}
                />
              <ButtonSolid 
                buttonText={"Save Changes"}
                disabled={fetchingTemplateDetails}
                onClick={() => saveTemplateChanges(templateModelDesc['id'])}
                />
            </div>
          </div>
          <div className='bg-white shadow-md rounded-lg mb-5 w-full overflow-hidden'>
            <div className='bg-white pb-4'>
              <div className='flex flex-wrap items-start md:items-center justify-between py-4 px-6'>
                <p className='text-2xl font-semibold mr-2 mb-2 md:mb-0 pr-2'>Assemblies</p>
                <div className='flex'>
                  <ButtonOutline 
                    buttonText={"Delete This Template"}
                    className={"mr-4 lg:mr-0"}
                    disabled={fetchingTemplateDetails}
                    onClick={() => setOpenDeletePopup(true)}
                  />
                  <ButtonSolid 
                    buttonText={"Add Assembly"}
                    onClick={() => setToggleSearchPopup(true)}
                    disabled={fetchingTemplateDetails}
                    className='lg:hidden'
                  />
                </div>
              </div>
              {fetchingTemplateDetails ?
                <LoadingAnimation /> :
                <div className='overflow-x-auto'>
                    {Object.keys(templateDetails).map((key, index) => (
                    (templateDetails[key].length > 0) &&
                    <div key={key} className={`border border-gray-300 mb-6 mx-6 rounded-lg overflow-hidden min-w-fit  transition-all ease-in-out duration-300 `} >
                      <div className='py-4 px-4 text-xs md:text-base font-semibold bg-slate-50 capitalize text-gray-700 cursor-default'>{key}</div>
                      <table key={`${key}-${index}`} id={key} className='w-full min-w-[525px] text-sm text-left rtl:text-right cursor-default'>
                          <thead>
                              <tr className='text-xs md:text-base text-gray-400 uppercase border-b-2 border-dotted h-10'>
                                  <th className="px-4">{(index === 0) ? 'MODEL#' : 'ASSEMBLY#'}</th>
                                  <th className="px-4">FILE</th>
                                  <th className="px-4">ASSEMBLY DESCRIPTION</th>
                                  <th className="px-4 text-center">ACTION</th>
                              </tr>
                          </thead>
                          {templateDetails[key]?.map((item, itemIndex) => (
                          <tbody key={itemIndex}>
                              <tr className={`hover:bg-slate-200 border ${addedItems.includes(item._id) ? 'gradient_background_animated' : 'bg-transparent'} text-sm md:text-base text-gray-600`}>
                                <td className="pl-4 py-6 min-w-[200px]">
                                  <div>
                                    <p className='inline-block ml-2'>{item?.model_number} {item?.assembly_number}</p>
                                  </div>
                                </td>
                                <td className="px-4 py-6 md:min-w-[100px]">
                                  <span onClick={() => {showDrawing(item?.assets)}} className='cursor-pointer border-b border-gray-600 hover:border-orange-600'>Drawing</span>
                                </td>
                                <td className="px-4 py-6 w-full">{item?.assembly_description}{item?.model_description}</td>
                                <td className="px-4 py-6">
                                  {(item?.model_number === templateModelDesc["name"]) ? '' :
                                  <div onClick={() => {deleteAssemblyData(key, item._id)}} className='w-fit mx-auto cursor-pointer'>
                                    <DeleteIcon />
                                  </div>}
                                </td>
                              </tr>
                          </tbody>))}
                      </table>
                  </div>))}
                </div>} 

                {filesPopup && 
                (<PopupWithClose closePopup={setFilesPopup}>
                    <AssetList data={assetsData} fetchingAssets={fetchingAssets}/>
                </PopupWithClose>)
              }
            </div>
          </div>
        </div>

        <MODsAssemblyList fetchingTemplateDetails={fetchingTemplateDetails} addAssemblyData={addAssemblyData} setToggleSearchPopup={setToggleSearchPopup} toggleSearchPopup={toggleSearchPopup} />

        {openDeletePopup &&
          (<DeletePopUp onDelete={() => deleteTemplate(templateModelDesc['id'])} dataName={`MODSs template ${templateModelDesc['name']}`} setOpenDeletePopup={setOpenDeletePopup}/>)
        }
      </div>
    </>
  )
}

export default ModsTemplateEdit