import toast from "react-hot-toast";
import ButtonSolid from "../Components/ButtonSolid"
import FileUploadDragDrop from "../Components/FileUploadDragDrop"
import { useState, useContext } from "react";
import axios from 'axios';
import { baseURL } from "../config";
import OverlayLoading from "../Components/OverlayLoading";
import AuthContext from "../Context/AuthContext";

function MODsTemplateUploadForm({refresh, setRefresh}) {
  const [overlay, setOverlay] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fileUploadError, setFileUploadError] = useState("")
  const [bulkUploadFile, setBulkUploadFile] = useState();
  const { token } = useContext(AuthContext)

  const bulkUploadFile_DragDropUpload = (event) => {
    event.preventDefault();
    setFileUploadError('')
    const files = event.dataTransfer.files;
    if (files.length > 1) {
      setFileUploadError('Please upload only one file at a time.');
      return;
    }
    const file = files[0];
    if (file.type !== 'text/csv' && !file.name.toLowerCase().endsWith('.csv')) {
      setFileUploadError('Please upload a CSV file only.');
      return;
    }
    
    const droppedFile = files[0];
    setBulkUploadFile(droppedFile); 
  };

  const bulkUploadFile_ManualUpload = (e) => {
    const files = e.target.files;
    if (!files || files.length !== 1) {
      setFileUploadError('Please upload only one file at a time.');
      return;
    }
    const file = files[0];
    if (file.type !== 'text/csv' && !file.name.toLowerCase().endsWith('.csv')) {
      setFileUploadError('Please upload a CSV file only.');
      return;
    }
    
    setBulkUploadFile(file);
  };

  const handleBulkUploadSubmit = async (e) => {
    e.preventDefault();
    if (bulkUploadFile) {
      const formData = new FormData();
      formData.append('template', bulkUploadFile);
    
      try {
        setLoading(true)
        setOverlay(true)
        const response = await axios.post(`${baseURL}/template/upload`, formData,  
        {
          headers: {
            "ngrok-skip-browser-warning": "any",
            "Content-Type": "multipart/form-data",
            "x-access-token": token
        },
      }
      );
    
        if (response.status === 201) {
          toast.success('File Uploaded Successfully');
          setRefresh(!refresh)
        } else {
          toast.error('Failed To Upload File');
          setBulkUploadFile()
        }
      } catch (error) {
        if (error.response.status === 400){
          toast.error('Please Upload Valid CSV File');
        } else {
          toast.error('Error Uploading File: ' + error.message);
        }
        setBulkUploadFile()
      } finally {
        setLoading(false)
        setOverlay(false)
      }
    } else {
      setFileUploadError('Please add a CSV file.');
    }
  };

  return(
    <>
      {overlay && (
        <OverlayLoading loadingText="Uploading MODs Template"/>
      )}
      <form onSubmit={handleBulkUploadSubmit}>
        <h2 className="text-center text-2xl font-medium mb-10">Create MODs Template</h2>
        <p className="text-center mb-3">Upload File</p>
        <FileUploadDragDrop
          className="mx-auto"
          handleDrop={bulkUploadFile_DragDropUpload}
          handleUpload={bulkUploadFile_ManualUpload}
          file={bulkUploadFile}
          errorMessage={fileUploadError}
        />
        <ButtonSolid
          type="submit"
          isLoading={loading}
          loadingText={'Uploading MOD'}
          className = "mx-auto block"
          buttonText = "Upload"
        />
      </form>
    </>
  )
}

export default MODsTemplateUploadForm