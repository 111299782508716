import { useState } from 'react'
import ButtonSolid from '../Components/ButtonSolid'
import ButtonOutline from '../Components/ButtonOutline'
import toast from 'react-hot-toast'
import MODsAssemblyList from '../Components/MODsAssemblyList'
import TemplateHeaders from '../Components/TemplateHeaders'
import CustomerDetailForm from '../Components/CustomerDetailForm'
import OverlayLoading from '../Components/OverlayLoading'
import TemplateAssemblyList from '../Components/TemplateAssemblyList'

function UseEditTemplate({
    savingMachineOrders,
    originalSelectedAssembly,
    templateModelDesc,
    templateDetails,
    setTemplateDetails,
    originalTemplateDetails,
    fetchingTemplateDetails,
    accountNumberOptions,
    setAccountNumberOptions,
    selectedAssembly,
    setSelectedAssembly,
    mods_data,
    setMods_data,
    customer_data,
    setCustomer_data,
    assignedQR,
    setAssignedQR,
    validateFields,
    saveChanges,
    originalCustomerData,
    originalModsData,
    setQR,
    editOrder
}) {
  const [toggleSearchPopup, setToggleSearchPopup] = useState(false)
  const [disableReset, setDisableReset] = useState(true)
  const [addedItems, setAddedItems] = useState([])
  
  const resetChanges = () => {
    setTemplateDetails(originalTemplateDetails)
    setSelectedAssembly(originalSelectedAssembly)
    if (editOrder) {
      setMods_data(originalModsData)
      setCustomer_data(originalCustomerData)
    } else {
      setMods_data({
        sales_order_number: '',
        customer_po: '',
      })
      setCustomer_data({
        account_number: '',
        name: '',
        company_name: '',
        primary_contact: '',
        primary_email: '',
        accounting_contact: '',
        accounting_email: '',
        bill_to: '',
        ship_to: '',
        others: '',
        shipment_date: '',
      })
    }
    setDisableReset(true)
  }

  const addAssemblyData = (dataSet) => {
    const assemblyType = dataSet.assembly_type;
    const assemblyToAdd = originalTemplateDetails[assemblyType]?.findIndex(item => item._id === dataSet._id);
    const indexToAdd = templateDetails[assemblyType]?.findIndex(item => item._id === dataSet._id);
    if (((assemblyToAdd === -1) || (originalTemplateDetails[assemblyType] === undefined)) & ((indexToAdd === -1) || (templateDetails[assemblyType] === undefined))) {
      setAddedItems(prevAddedItems => [...prevAddedItems, dataSet._id]);
    }

    if ((indexToAdd === -1) || (templateDetails[assemblyType] === undefined)) {
      setTemplateDetails(prevState => ({
        ...prevState, // Clone the existing state
        [assemblyType]: [ // Add new data under the respective assembly type
            ...(prevState[assemblyType] || []), // Clone existing data for the assembly type, if any
            dataSet // Add the new data from the dataSet
        ]
      }));
      setDisableReset(false);
      const element = document.getElementById(assemblyType);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    else {
      toast.error("Assembly Already Exist")
    }
  };

  return (
    <div>
      {savingMachineOrders && (
        <OverlayLoading loadingText='Saving Order Changes...'/>
      )}
      <div className='flex w-full lg:h-[calc(100vh-80px)]'>
        <div className='w-full lg:pr-3 overflow-y-auto'>
          <div className='flex flex-wrap items-start md:items-center justify-between py-3 md:py-5 gap-2'>
            <TemplateHeaders templateModelDesc={templateModelDesc} loading={fetchingTemplateDetails} machineOrder={true} />
            <div className='mr-0 ml-auto'> 
              <ButtonOutline 
                buttonText={"Reset Changes"}
                disabled={disableReset}
                className="mr-4 mb-2"
                onClick={() => resetChanges()}
              />
              <ButtonSolid 
                buttonText={`${editOrder ? "Save Changes" : "Create Order" }`}
                disabled={fetchingTemplateDetails}
                onClick={() => saveChanges()}
              />
            </div>
          </div>
          <div id='OrderForn'>
            <CustomerDetailForm 
              loadingData = {fetchingTemplateDetails}
              mods_data={mods_data}
              customer_data={customer_data}
              accountNumberOptions={accountNumberOptions}
              setAccountNumberOptions={setAccountNumberOptions}
              setMods_data={setMods_data}
              setCustomer_data={setCustomer_data}
              validateFields={validateFields}
              assignedQR={assignedQR} 
              setAssignedQR={setAssignedQR}
              setQR={setQR}
              editOrder={editOrder}
              setDisableReset={setDisableReset}
              originalCustomerData={originalCustomerData}
            />
          </div>
          <TemplateAssemblyList
            setToggleSearchPopup = {setToggleSearchPopup}
            fetchingTemplateDetails = {fetchingTemplateDetails}
            templateDetails = {templateDetails}
            addedItems = {addedItems}
            templateModelDesc = {templateModelDesc}
            selectedAssembly = {selectedAssembly}
            setSelectedAssembly = {setSelectedAssembly}
            setDisableReset = {setDisableReset}
          />
        </div>

        <MODsAssemblyList fetchingTemplateDetails={fetchingTemplateDetails} addAssemblyData={addAssemblyData} setToggleSearchPopup={setToggleSearchPopup} toggleSearchPopup={toggleSearchPopup} />
      </div>
    </div>
  )
}

export default UseEditTemplate