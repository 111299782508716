import PageNotFoundImg from '../../src/Assets/PageNotFoundImg.png'
import LinkButtonSolid from '../Components/LinkButtonSolid'

function LandingPage404() {
  return (
    <>
      <nav className='flex justify-between p-4'>
        <a href={'https://www.choicebagging.com/'} rel="noopener noreferrer">
          <img
              className="h-auto w-[135px]"
              src="/CHOICE-BAGGING-NEW-LOGO-300x93-corrected-2.png"
              alt="Company Logo"
          />
        </a>
        <LinkButtonSolid
            linkTo="tel:512-352-3694"
            buttonText='Call for enquiry'
            className={'lg:hidden'}
        /> 
      </nav>
      <div className='py-3 md:py-6'>
        <div className='bg-white rounded-lg w-full max-w-[600px] py-3 md:py-6 mx-auto'>
          <img src={PageNotFoundImg} alt='page not found' className='mx-auto'/>
          <p className='text-xl font-semibold text-gray-700 text-center px-2 md:hidden'>This QR code is not associated with any of the EQID Tool pages</p>
          <p className='text-2xl font-semibold text-gray-700 text-center px-2 hidden md:block'>This Equipment Serial Number is not associated with any of the EQID Tool pages</p>
        </div>
      </div>
    </>
  )
}

export default LandingPage404