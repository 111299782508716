import { useState } from "react";
import LoadingAnimation from "./LoadingAnimation";
import { CloseCircleIcon, UpRightArrowIcon } from "./Icons";

const InputWithSuggestion = ({
   labelText,
   value='',
   errorMessage,
   name, 
   options=[], 
   selected='', 
   setSelected, 
   placeholder = '', 
   onChange, 
   isLoading = false, 
   disabled=false, 
   type='text',
   autoFillUsed,
   setAutoFillUsed,
   autoFillUsing,
   readOnly=false,
   resetCustomerDetails
  }) => {

  const [open, setOpen] = useState(false);
  const [mouseIn, setMouseIn] = useState(false);

  const hideDropdown = (e) => {
    if (mouseIn) {
      e.stopPropagation()
    } else {
      setOpen(false)
    }
  }

  const handleOptionClick = (item, e) => {
    e.stopPropagation()
    setSelected(item);
    setAutoFillUsed(autoFillUsing)
    setOpen(false)
  }

  const handleInputClick = () => {
    if (options.length > 0 && !disabled) {
      setOpen(true)
    }
    setMouseIn(false)
  }

  const filteredOptions = options.filter((item) =>
    item.name ? 
      item.name.toLowerCase().startsWith(value.toLowerCase()) : 
      item.toLowerCase().startsWith(value.toLowerCase())
  );

  const resetAutoFill = () => {
    if (!disabled) {
      setSelected()
      resetCustomerDetails()
    }
  }

  return (
    <div onBlur={(e) => hideDropdown(e)} className="w-full">
      <div className="w-full relative" onClick={() => handleInputClick()}>
        <p className='block font-medium text-gray-700 w-full'>{labelText}</p>
        <label className="relative">
          <input
              tabIndex={0}
              value={value}
              disabled={disabled}
              onChange={onChange}
              placeholder={placeholder}
              name={name}
              type={type}
              readOnly={readOnly}
              autoComplete="off"
              className={`block w-full h-10 md:h-12 rounded-md border border-gray-300 focus:outline-gray-400 py-2 px-3 md:px-4 text-gray-900 placeholder:text-gray-400 sm:text-sm disabled:bg-gray-100 disabled:cursor-not-allowed sm:leading-6 min-w-32`}
          />
          {((autoFillUsed === autoFillUsing) && (selected) ) &&
          <span className={`absolute right-0 top-1/2 transform -translate-y-1/2 mr-2 z-30 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => resetAutoFill()}><CloseCircleIcon/></span>}
        </label>
        <p className="text-red-600 text-sm min-h-5">{errorMessage || ''}</p>
        {open && 
        <>
        {isLoading ? (
          <LoadingAnimation />
        ) : (
          options && (
            <div>
              <ul onMouseDown={() => setMouseIn(true)} className={`${open ? 'block' : 'hidden'} text-gray-700 bg-white cursor-pointer absolute top-full -mt-4 z-10 left-0 right-0 
              ${options.some(item => item.name ? item.name.startsWith(value) : item.startsWith(value)) ? 'border-x-2 shadow-lg' : ''}  overflow-x-hidden max-h-60 overflow-y-auto`}>
                {filteredOptions?.map((item, index) => (
                  <li key={item + index}
                    className={`hover:bg-gray-300
                    ${item.name ? 
                    `${item.name.toLowerCase() === selected['name']?.toLowerCase() && "bg-gray-200"}`:
                    `${item?.toLowerCase() === selected?.toLowerCase() && "bg-gray-200"}`}`}
                  >
                    <div className="flex justify-between items-center p-2 py-3 max-w-full" onClick={(e) => handleOptionClick(item, e)}>
                      <p className="overflow-ellipsis overflow-x-hidden">{item.name ? item.name : item}</p>
                      <span className="min-w-4">
                        <UpRightArrowIcon />
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )
        )}
        </>
        }
      </div>
    </div>
  );
};

export default InputWithSuggestion;