import { useContext, useEffect, useRef, useState } from 'react'
import ButtonSolid from '../Components/ButtonSolid'
import { baseURL } from '../config';
import axios from 'axios';
import LoadingAnimation from '../Components/LoadingAnimation';
import OverlayLoading from '../Components/OverlayLoading';
import toast from 'react-hot-toast';
import AuthContext from '../Context/AuthContext';
import ButtonOutline from '../Components/ButtonOutline';
import Popup from '../Components/Popup';
import GenerateQRpopup from '../Components/GenerateQRpopup';
import ScanQRcode from '../../src/Assets/ScanQRcode.png'
import { saveAs } from 'file-saver';
import Dropdown from '../Components/Dropdown';
import PopupWithClose from '../Components/PopupWithClose';
import { Link } from 'react-router-dom';
import { CloseIcon, RightArrowIcon } from '../Components/Icons';
import moment from 'moment';
import Searchbar from '../Components/Searchbar';

function QRcodeListView() {
  const [listedQR, setListedQR] = useState([]);
  const [loading, setLoading] = useState(true);
  const [overlay, setOverlay] = useState(false);
  const [popupQRform, setPopupQRform] = useState(false)
  const { token, logout } = useContext(AuthContext)
  const [selectedFilter, setSelectedFilter] = useState('All')
  const [customerDetailPopup, setCustomerDetailPopup] = useState(false)
  const [customerDetails, setCustomerDetail] = useState()
  const [fetchingCustomerDetails, setFetchingCustomerDetails] = useState(true)
  const [dateQRMap, setDateQRMap] = useState([])
  const [selectAllDates, setSelectAllDates] = useState([])
  const [selectedQRID, setSelectedQRID] = useState([])
  const limit = 4
  const [params, setParams] = useState({
    page: 1,
    limit: limit,
    filter: 'All',
    search: ""
  })
  const [searchInput, setSearchInput] = useState('')
  const observer = useRef()
  const [totalPages, setTotalPages] = useState(1);

  const resetParams = () => {
    if (loading) {
      return
    }
    setListedQR([])
    setSelectedQRID([])
    setSelectAllDates([])
    setSearchInput("")
    setParams({
      page: 1,
      limit: limit,
      filter: 'All',
      search: ""
    });
  }

  const separateDateFromValues = (dateValueSet) => {
    const dates = Object.keys(dateValueSet);
    setSelectAllDates(dates);
  };

  const handleSetSelectedQR = (e, value) => {
    e.stopPropagation();
    
    setSelectedQRID(prevSelectedQRID => {
      let updatedSelectedQR;
      if (prevSelectedQRID.includes(value)) {
        // Remove the value from selectedQRID
        updatedSelectedQR = prevSelectedQRID.filter(i => i !== value);
      } else {
        // Add the value to selectedQRID
        updatedSelectedQR = [...prevSelectedQRID, value];
      }
  
      // Update the dateQRMap accordingly
      setDateQRMap(() => {
        let updatedDateQRMap = { ...dateQRMap };
  
        // Check if the value exists in any of the dates in dateQRMap
        for (let key in updatedDateQRMap) {
          if (updatedDateQRMap[key].includes(value)) {
            // Remove the date and its associated values
            const { [key]: removed, ...rest } = updatedDateQRMap;
            updatedDateQRMap = rest;
            break;
          }
        }
        separateDateFromValues(updatedDateQRMap)
        return updatedDateQRMap;
      });
  
      return updatedSelectedQR;
    });
  };
  

  const handleSelectAll = (date) => {
    // Find the object with the specified date
    const selectedDateObject = listedQR.find(item => item.date === date);
  
    setDateQRMap(prevDateQRMap => {
      let updatedDateQRMap;
  
      // Check if the date already exists in dateQRMap
      if (prevDateQRMap[date]) {
        // Remove the date from dateQRMap
        const { [date]: removed, ...rest } = prevDateQRMap;
        updatedDateQRMap = rest;
  
        // Remove the QR strings associated with the removed date from selectedQRID
        setSelectedQRID(prevSelectedQRID => prevSelectedQRID.filter(qr_id => !removed.includes(qr_id)));
      } else {
        // If the date is not in dateQRMap and the object with the specified date is found
        if (selectedDateObject) {
          // Extract all qrString values from the qrCodes array within that date
          const newQRStrings = selectedDateObject.qrCodes.map(qrCode => qrCode.qr_id);
          // Add the new date and its QR strings to dateQRMap
          updatedDateQRMap = {
            ...prevDateQRMap,
            [date]: newQRStrings
          };
  
          // Add the new QR strings to selectedQRID using a Set to avoid duplicates
          setSelectedQRID(prevSelectedQRID => Array.from(new Set([...prevSelectedQRID, ...newQRStrings])));
        } else {
          // If date not found in data, return previous dateQRMap
          return prevDateQRMap;
        }
      }
  
      const dates = Object.keys(updatedDateQRMap);
      setSelectAllDates(dates);
      return updatedDateQRMap;
    });
  };
  
  const foundSearch = document.getElementById(searchInput);
  if (foundSearch) {
    foundSearch.scrollIntoView({ behavior: "smooth" });
  }

  const handleQRSearch = (input) => {
    if (input.length <= 6) {
      setSearchInput(input);
      if (input.length === 6) {
        setLoading(true);
        setListedQR([])
        setSelectedQRID([])
        setSelectAllDates([])
        setSelectedFilter("All")
        setParams(prevParams => ({
          ...prevParams,
          search: input,
          filter: "All",
          page: 1
        }));
      }
      if (input.length === 0) {
        setListedQR([])
        setSelectedQRID([])
        setSelectAllDates([])
        setSelectedFilter("All")
        setParams(prevParams => ({
          ...prevParams,
          search: '',
          filter: "All",
          page: 1
        }));
      }
    }
  };

  const QRdataInfiniteScroll  = (node) => {
    if (loading) return
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if(entries[0].isIntersecting) {
        if (params.page < totalPages) {
          setParams(prevParams => ({
            ...prevParams,
            page: prevParams.page + 1
          }));
        }
      }
    })
    if (node) observer.current.observe(node);
  }

  const handleSelectedFilter = (value) => {
    setSelectedFilter(value)
    setListedQR([])
    setSelectedQRID([])
    setSelectAllDates([])
    setLoading(true);
    setParams(prevParams => ({
      ...prevParams,
      filter: value,
      page: 1
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseURL}/qr/getQR`, {
          headers: {
            "ngrok-skip-browser-warning": "any",
            "x-access-token": token,
          }, params: params });
        if (response?.status === 204) {
          setListedQR('no data');
          setTotalPages(1)
        }
         else {
          // console.log('response', response)

          setListedQR(prev => {
            // Combine previous array and new array
            const combinedArray = [...prev, ...response.data.data?.qrCodesByDate];

            // Use a Map to ensure unique objects based on the 'date' property
            const uniqueObjects = new Map(combinedArray.map(item => [item.date, item]));

            // Convert the Map back to an array
            return [...uniqueObjects.values()];
          });
          setTotalPages(response.data.data?.pagination.totalPages)
        }
      } catch (error) {
        console.error('Error:', error);
        if (error.response?.status === 401) {
          logout();
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [ params, logout, token])

  const showCustomerDetails = (e, item) => {
    if (item.is_assigned) {
      e.stopPropagation()
      setCustomerDetailPopup(true)
      const fetchCustomerDetails = async (item) => {
        try {
          setFetchingCustomerDetails(true)
          const response = await axios.get(`${baseURL}/mods/getModsByQRId/${item.qr_id}`, {
            headers: {
              "ngrok-skip-browser-warning": "any",
              "Content-Type": "multipart/form-data",
              "x-access-token": token
            },
            params: {
              qr_id: item.qr_id,
            }
          });
          setCustomerDetail(response.data.data.modsData)
        } catch (error) {
          console.error('Error:', error);
          if (error.response?.status === 401) {
            logout();
          }
        } finally {
          setFetchingCustomerDetails(false)
        }
      };
      fetchCustomerDetails(item)
    }
  }

  const downloadPDF = async() => {
    try {
      setOverlay(true)
      const response = await axios.post(`${baseURL}/qr/exportUsingId`, { qr_ids: selectedQRID },
      {headers: {
        "ngrok-skip-browser-warning":"any",
        "x-access-token":token
      },
      responseType: 'blob'
    });
      const filename = 'qr.pdf'
      const blob = await response.data;
      saveAs(blob, filename);
    } catch (error) {
      toast.error('Error Downloading PDF:', error);
    } finally {
      setOverlay(false)
    }
  }

  if (listedQR.length === 0 && loading === false) {
    return (
      <div className='py-3 md:py-6'>            
        <div className='bg-white rounded-lg w-full h-full shadow-md'>
            <div className='py-10'>
                <h3 className='text-center text-lg font-medium mb-3'>Generate QR Codes</h3>
                <p className='text-center font-medium text-gray-500 mb-9'>Generate bulk QR codes with “Generate QR codes button”</p>
                <img src={ScanQRcode} alt='page not found' className='mx-auto mb-9'/>
                <ButtonSolid
                  buttonText="Generate QR Codes"
                  className="block mx-auto"
                  onClick={() => setPopupQRform(true)}
                />
            </div>
            {popupQRform && 
              <GenerateQRpopup setPopupQRform={setPopupQRform} resetParams={resetParams} />
            }
        </div>
      </div>
    )
  }

  return (
    <>
      {overlay && (
          <OverlayLoading />
      )}
      <div className='flex flex-wrap justify-between items-center py-5'>
          <p className='text-xl font-bold md:font-semibold pr-3'>QR Codes</p>
          <div className='flex flex-wrap gap-4'>
            <ButtonOutline
              disabled={loading}
              buttonText="Generate"
              onClick={() => setPopupQRform(true)} 
            />
            <ButtonSolid
              disabled={loading || (listedQR === 'no data') || (selectedQRID.length === 0)}
              buttonText="Export as PDF"
              onClick={() => downloadPDF()}
            />
          </div>
      </div>
      <div className="overflow-x-auto px-6 py-3 bg-white shadow-md rounded-lg mb-6 min-h-80">
        <div className='flex justify-between items-center flex-wrap mt-2 mb-4'>
          <div className='flex'>
            <h3 className='text-lg font-medium mb-2'>QR Codes Generated</h3>
            {(selectedQRID.length > 0) && <p className='text-gray-500 pl-2'>({selectedQRID.length} selected)</p>}
          </div>
          <div className='flex flex-wrap gap-4 items-center w-full md:w-fit'>
            <div className='relative min-w-[280px]'>
              <Searchbar
                value={searchInput}
                onChange={(e) => handleQRSearch(e.target.value)}
                fullWidth={true}
                placeholder='Serial Number Search'
                type={'number'}
                disabled={loading}
              />
              {(searchInput.length < 6 )?
                <span className='absolute top-1/2 transform -translate-y-1/2 right-4 text-gray-400'>{searchInput.length}/6</span> :
                <span className='absolute top-1/2 transform -translate-y-1/2 right-4 text-gray-400' onClick={resetParams}><CloseIcon/></span>
              }
            </div>
            <div className='flex gap-4 items-center w-full md:w-fit'>
              <p className='font-medium text-base'>Status</p>
              <Dropdown value={['All', 'Assigned', 'Unassigned']} selectedValue={selectedFilter} setSelectedValue={handleSelectedFilter} disabled={loading || (searchInput.length === 6)}/>
            </div>
          </div>
        </div>        
          
        {(!loading && listedQR === 'no data') ?
        <p className='p-4'>{selectedFilter === 'All' ? 'No QR Code Available' : `No ${selectedFilter} QR Code Available`}</p> 
        : listedQR.map((entry, index) => {
            if (listedQR.length === index +1) {
          return (
            <div key={entry.date} ref={QRdataInfiniteScroll} className='border-2 rounded-md mb-6'>
              <div className='bg-gray-100 border-b-2 py-2 flex justify-between items-center px-4'>
                <div className='flex'>
                  <p className='pr-4 font-semibold text-md text-gray-500'>{entry.date}</p>
                  <p className='text-gray-500'>{entry.qrCodes.length} code{entry.qrCodes.length > 1 ? 's' : ''}</p>
                </div>
                <div className='flex gap-2'>
                  <p>Select All</p>
                  <input type='checkbox' onClick={() => handleSelectAll(entry.date)} checked={selectAllDates.includes(entry.date)} readOnly={true} className='block h-6 w-6 accent-orange-600'/>
                </div>
              </div>
              <div className='flex flex-wrap gap-y-3 justify-center md:justify-start items-center py-4'>
              {entry.qrCodes.map((qr) => (
                <div id={qr.qr_id} key={qr.qr_id} onClick={e => handleSetSelectedQR(e, qr.qr_id)} readOnly={true} className='relative px-2'>
                  <div className={`px-2 py-4 border border-gray-300 ${(searchInput === qr.qrString) ? 'gradient_background_animated' : 'bg-none'} hover:border-orange-400 hover:scale-[1.01] hover:shadow-xl transition-all ease-in-out cursor-pointer`}>
                    <img src={`data:image/png;base64,${qr.base64_string}`} alt={`QR Code ${index + 1}`} width="200px" height="200px" />
                    <div className='flex flex-col justify-center border-t border-gray-300'>
                      <p className='mx-6 mb-1 text-center font-medium'>{qr.qrString}</p>
                      <p onClick={(e) => showCustomerDetails(e, qr)} className={`text-center font-medium rounded-lg w-fit px-4 mx-auto ${(qr.is_assigned) ? 'bg-green-100 text-green-800 cursor-pointer' : 'bg-blue-100 text-blue-800 cursor-default'}`}>{qr.is_assigned ? 'Assigned' : 'Unassigned'}</p>
                    </div>
                  </div>
                  <input type='checkbox' checked={selectedQRID.includes(qr.qr_id)} readOnly={true} className='absolute top-1 right-3 h-6 w-6 accent-orange-600'/>
                </div>))}
              </div>
            </div>)
          } else {
            return (
              <div key={entry.date} className='border-2 rounded-md mb-6'>
                <div className='bg-gray-100 border-b-2 py-2 flex justify-between items-center px-4'>
                  <div className='flex'>
                    <p className='pr-4 font-semibold text-md text-gray-500'>{entry.date}</p>
                    <p className='text-gray-500'>{entry.qrCodes.length} code{entry.qrCodes.length > 1 ? 's' : ''}</p>
                  </div>
                  <div className='flex gap-2'>
                    <p>Select All</p>
                    <input type='checkbox' onClick={() => handleSelectAll(entry.date)} checked={selectAllDates.includes(entry.date)} readOnly={true} className='block h-6 w-6 accent-orange-600'/>
                  </div>
                </div>
                <div className='flex flex-wrap gap-y-3 justify-center md:justify-start items-center py-4'>
                {entry.qrCodes.map((qr) => (
                  <div id={qr.qr_id} key={qr.qr_id} onClick={e => handleSetSelectedQR(e, qr.qr_id)} readOnly={true} className='relative px-2'>
                    <div className={`px-2 py-4 border border-gray-300 ${(searchInput === qr.qrString) ? 'gradient_background_animated' : 'bg-none'} hover:border-orange-400 hover:scale-[1.01] hover:shadow-xl transition-all ease-in-out cursor-pointer`}>
                      <img src={`data:image/png;base64,${qr.base64_string}`} alt={`QR Code ${index + 1}`} width="200px" height="200px" />
                      <div className='flex flex-col justify-center border-t border-gray-300'>
                        <p className='mx-6 mb-1 text-center font-medium'>{qr.qrString}</p>
                        <p onClick={(e) => showCustomerDetails(e, qr)} className={`text-center font-medium rounded-lg w-fit px-4 mx-auto ${(qr.is_assigned) ? 'bg-green-100 text-green-800 cursor-pointer' : 'bg-blue-100 text-blue-800 cursor-default'}`}>{qr.is_assigned ? 'Assigned' : 'Unassigned'}</p>
                      </div>
                    </div>
                    <input type='checkbox' checked={selectedQRID.includes(qr.qr_id)} readOnly={true} className='absolute top-1 right-3 h-6 w-6 outline-2 outline-orange-400 accent-orange-600'/>
                  </div>))}
                </div>
              </div>
            )
          }})}

        {loading && (<div className='p-4'><LoadingAnimation /></div>)}
      </div>
      
      {popupQRform &&
        (<Popup>
          <GenerateQRpopup setPopupQRform={setPopupQRform}  resetParams={resetParams}/>
        </Popup>)
      }

      {customerDetailPopup &&
      <PopupWithClose closePopup={setCustomerDetailPopup}>
        <div className='md:px-3'>
          <h3 className='mb-3 text-2xl font-bold'>MODs Details</h3>
          {fetchingCustomerDetails ? 
          <LoadingAnimation/> :
          <div>
            <table className='w-full mb-2 text-gray-700'>
              <tbody>
                <tr>
                  <th className='py-1'>Model #</th>
                  <td className='py-1'>{customerDetails?.model_number}</td>
                </tr>
                <tr>
                  <th className='py-1'>Sales Order #</th>
                  <td className='py-1 uppercase'>{customerDetails?.sales_order_number}</td>
                </tr>
                <tr>
                  <th className='py-1'>Serial #</th>
                  <td className='py-1'>{customerDetails?.serial_number}</td>
                </tr>
                <tr>
                  <th className='py-1'>Customer PO #</th>
                  <td className='py-1 uppercase'>{customerDetails?.customer_po}</td>
                </tr>
                <tr>
                  <th className='py-1'>Created At</th>
                  <td className='py-1'>{moment(customerDetails?.created_at).format('ll')}</td>
                </tr>
              </tbody>
            </table>
            <div>
              <Link className='flex ml-auto mr-0 border-b border-transparent w-fit text-blue-600 hover:border-blue-600' to={`../machine_orders/machine_orders_detail_view/${customerDetails?.id}`}>Show more details <span><RightArrowIcon/></span></Link>
            </div>
          </div>}
        </div>
      </PopupWithClose>
      }
    </>
  )
}

export default QRcodeListView